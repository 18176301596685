import { IPhoneNumber } from "../interfaces/phone-number.interface";

export const constructInitialPhoneNumberValue = (): IPhoneNumber => ({
  internationalNumber: "",
  number: "",
  countryCode: "",
});

export const getNationalNumberFromInternationalNumber = (number: string): string => {
  const firstSpaceIndex = number.indexOf(" ");
  if (firstSpaceIndex !== -1) {
    return number.slice(firstSpaceIndex).trim();
  }
  return number;
};
