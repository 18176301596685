import { HttpClient, HttpParams } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { APIS } from "@root/core/api/market.apis";
import { ILinkedPackageUnit } from "@root/data/market/linked/models/linked-package-unit.model";
import { ILookUpItem } from "@root/data/market/lookups/models/look-up-item.model";
import { ICreatePropertyEventPayload } from "@root/data/market/properties/models/create-property-event-payload.model";
import { IEditPropertyEventPayload } from "@root/data/market/properties/models/edit-property-event-payload.model";
import { IEventAttendedCustomer } from "@root/data/market/properties/models/event-attended-customer.model";
import { IEventCustomerAppointment } from "@root/data/market/properties/models/event-customer-appointment.model";
import { IEventTimeslotAppointment } from "@root/data/market/properties/models/event-timeslot-appointment.model";
import { IMatchCustomerResponse } from "@root/data/market/properties/models/match-customer-response.model";
import { IPropertyEventAssignedUser } from "@root/data/market/properties/models/property-event-assigned-user.model";
import { IEventAppointmentQuery } from "@root/data/market/properties/queries/event-appointment.query";
import { IEventTimeslotAppointmentQuery } from "@root/data/market/properties/queries/event-timeslot-appointment.query";
import { IMatchCustomerQuery } from "@root/data/market/properties/queries/match-customer.query";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IPropertyEventCustomersPayload } from "@root/shared/interfaces/property-event-customers-payload.interface";
import { IPropertyEventInstancesPayload } from "@root/shared/interfaces/property-event-instances-payload.interface";
import { Observable } from "rxjs/internal/Observable";
import { IAvailableTimeSlot } from "../models/available-time-slots.model";
import { IChangeTimeSlotInputs } from "../models/change-time-slot-inputs.model";
import { IPropertyEventCustomers } from "../models/property-event-customers.model";
import { IPropertyEventDetails } from "../models/property-event-details.model";
import { IPropertyEventInstance } from "../models/property-event-instance.model";
import { removeNullsFromObject } from "@root/shared/utilities/object.utilities";
import { IInviteCustomerQuery } from "@root/data/market/properties/queries/invite-customer.query";
import { IInviteCustomerDto } from "@root/data/market/properties/models/invite-customer-dto.model";

@Injectable({
  providedIn: "root",
})
export class PropertiesEventsRepository {
  readonly #http = inject(HttpClient);

  getAvailableUsers(
    propertyId: number,
    params: IPaginationSortPayload & { eventDate: string },
  ): Observable<IPaginatedResponse<IPropertyEventAssignedUser>> {
    return this.#http.get<IPaginatedResponse<IPropertyEventAssignedUser>>(
      APIS.propertyEvents.getUsersWithAccessToProperty(propertyId),
      {
        params: new HttpParams({
          fromObject: { ...params },
        }),
      },
    );
  }

  getAvailableUsersLookups(propertyId: number): Observable<ILookUpItem[]> {
    return this.#http.get<ILookUpItem[]>(APIS.propertyEvents.getUsersWithAccessToPropertyLookups(propertyId));
  }

  create(propertyId: number, body: ICreatePropertyEventPayload): Observable<void> {
    return this.#http.post<void>(APIS.propertyEvents.create(propertyId), body);
  }

  getEventAppointments(query: IEventAppointmentQuery): Observable<IPaginatedResponse<IEventCustomerAppointment>> {
    return this.#http.get<IPaginatedResponse<IEventCustomerAppointment>>(
      APIS.propertyEvents.getAppointments(query.propertyId),
      {
        params: new HttpParams({
          fromObject: { ...query },
        }),
      },
    );
  }

  toggleAttendance(propertyId: number, appointmentId: number): Observable<void> {
    return this.#http.post<void>(APIS.propertyEvents.toggleAttendance(propertyId, appointmentId), null);
  }

  cancelAppointment(propertyId: number, appointmentId: number): Observable<void> {
    return this.#http.delete<void>(APIS.propertyEvents.cancelAppointment(propertyId, appointmentId));
  }

  getAttendedCustomers(query: IEventAppointmentQuery): Observable<IPaginatedResponse<IEventAttendedCustomer>> {
    return this.#http.get<IPaginatedResponse<IEventAttendedCustomer>>(
      APIS.propertyEvents.getAttendedCustomers(query.propertyId, query.eventInstanceId),
      {
        params: new HttpParams({
          fromObject: { ...query },
        }),
      },
    );
  }

  toggleFavouriteCustomer(customerId: number): Observable<void> {
    return this.#http.post<void>(APIS.propertyEvents.toggleFavouriteCustomer, customerId);
  }

  inviteCustomerToEvent(dto: IInviteCustomerDto, query: IInviteCustomerQuery) {
    return this.#http.post<void>(
      APIS.propertyEvents.inviteCustomer(query.propertyId, query.eventId, query.eventInstanceId),
      dto,
    );
  }

  getEventDetails(propertyId: number, eventId: number): Observable<IPropertyEventDetails> {
    return this.#http.get<IPropertyEventDetails>(APIS.propertyEvents.getEventDetails(propertyId, eventId));
  }

  getEventInstances(
    propertyId: number,
    eventId: number,
    queryParams: IPropertyEventInstancesPayload,
  ): Observable<IPaginatedResponse<IPropertyEventInstance[]>> {
    return this.#http.get<IPaginatedResponse<IPropertyEventInstance[]>>(
      APIS.propertyEvents.getEventInstances(propertyId, eventId),
      {
        params: new HttpParams({
          fromObject: { ...queryParams },
        }),
      },
    );
  }

  getTimeslotAppointment(
    propertyId: number,
    query: IEventTimeslotAppointmentQuery,
  ): Observable<IEventTimeslotAppointment[]> {
    return this.#http.get<IEventTimeslotAppointment[]>(APIS.propertyEvents.getTimeslotAppointment(propertyId), {
      params: new HttpParams({
        fromObject: { ...query },
      }),
    });
  }

  getPropertyEventById(propertyId: number, eventId: number): Observable<IEditPropertyEventPayload> {
    return this.#http.get<IEditPropertyEventPayload>(APIS.propertyEvents.getPropertyEventById(propertyId, eventId));
  }

  editPropertyEventById(propertyId: number, eventId: number, body: IEditPropertyEventPayload): Observable<void> {
    return this.#http.put<void>(APIS.propertyEvents.editPropertyEventById(propertyId, eventId), body);
  }

  getUnitsAssignedToEvents(eventId: number): Observable<ILinkedPackageUnit[]> {
    return this.#http.get<ILinkedPackageUnit[]>(APIS.propertyEvents.getUnitsAssignedToEvents, {
      params: new HttpParams({
        fromObject: { eventId },
      }),
    });
  }

  getEventCustomers(
    propertyId: number,
    eventId: number,
    queryParams: IPropertyEventCustomersPayload,
  ): Observable<IPaginatedResponse<IPropertyEventCustomers[]>> {
    return this.#http.get<IPaginatedResponse<IPropertyEventCustomers[]>>(
      APIS.propertyEvents.getEventCustomers(propertyId, eventId),
      {
        params: new HttpParams({
          fromObject: { ...queryParams },
        }),
      },
    );
  }

  getAvailableTimeSlot(propertyId: number, eventInstanceId: number): Observable<IAvailableTimeSlot[]> {
    return this.#http.get<IAvailableTimeSlot[]>(APIS.propertyEvents.getAvailableTimeSlot(propertyId, eventInstanceId));
  }

  changeTimeSlot(propertyId: number, eventInstanceId: number, body: Partial<IChangeTimeSlotInputs>): Observable<void> {
    return this.#http.put<void>(APIS.propertyEvents.changeTimeSlot(propertyId, eventInstanceId), body);
  }

  stopRepetitionOnEventById(propertyId: number, eventId: number): Observable<void> {
    return this.#http.post<void>(APIS.propertyEvents.stopRepetitionOnEvent(propertyId, eventId), {});
  }

  matchCustomer(query: IMatchCustomerQuery): Observable<IMatchCustomerResponse> {
    const nonNullableQuery = removeNullsFromObject(query);
    return this.#http.get<IMatchCustomerResponse>(APIS.legalEntities.match, {
      params: new HttpParams({
        fromObject: { ...nonNullableQuery },
      }),
    });
  }
}
