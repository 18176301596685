import { inject, Injectable } from "@angular/core";
import { mapLookupItemsToDropdownOptions } from "@root/data/market/lookups/utilities/lookups.utilties";
import { ICreatePropertyEventPayload } from "@root/data/market/properties/models/create-property-event-payload.model";
import { IEventTimeslotAppointment } from "@root/data/market/properties/models/event-timeslot-appointment.model";
import { IEventAppointmentQuery } from "@root/data/market/properties/queries/event-appointment.query";
import { IEventTimeslotAppointmentQuery } from "@root/data/market/properties/queries/event-timeslot-appointment.query";
import { PropertiesEventsRepository } from "@root/data/market/properties/repositories/properties-events.repository";
import {
  mapAttendedCustomersToSmallTableInputOperator,
  mapEventAppointmentsToSmallTableInputOperator,
  mapPropertyEventUserToSmallTableInputOperator,
} from "@root/data/market/properties/utilities/properties-events.utilities";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { IPaginationSortPayload } from "@root/shared/interfaces/pagination-sort-payload.interface";
import { IPropertyEventCustomersPayload } from "@root/shared/interfaces/property-event-customers-payload.interface";
import { IPropertyEventInstancesPayload } from "@root/shared/interfaces/property-event-instances-payload.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/internal/operators/map";
import { IEditPropertyEventPayload } from "@root/data/market/properties/models/edit-property-event-payload.model";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { IMatchCustomerQuery } from "@root/data/market/properties/queries/match-customer.query";
import { IMatchCustomerResponse } from "@root/data/market/properties/models/match-customer-response.model";
import { IAvailableTimeSlot } from "../models/available-time-slots.model";
import { IChangeTimeSlotInputs } from "../models/change-time-slot-inputs.model";
import { IPropertyEventCustomers } from "../models/property-event-customers.model";
import { IPropertyEventDetails } from "../models/property-event-details.model";
import { IPropertyEventInstance } from "../models/property-event-instance.model";
import { IInviteCustomerModel } from "@root/data/market/properties/models/invite-customer.model";
import { InviteCustomerAdapter } from "@root/data/market/properties/adapters/invite-customer.adapter";
import { IInviteCustomerQuery } from "@root/data/market/properties/queries/invite-customer.query";

@Injectable({
  providedIn: "root",
})
export class PropertiesEventsService {
  readonly #propertiesEventsRepository = inject(PropertiesEventsRepository);
  readonly #inviteCustomerAdapter = inject(InviteCustomerAdapter);

  getAvailableUsers(
    propertyId: number,
    params: IPaginationSortPayload & { eventDate: string },
  ): Observable<ISignalPaginatedResponse<ISmallListTableInput>> {
    return this.#propertiesEventsRepository
      .getAvailableUsers(propertyId, params)
      .pipe(mapPropertyEventUserToSmallTableInputOperator());
  }

  getAvailableUsersLookups(propertyId: number): Observable<IDropdownOption[]> {
    return this.#propertiesEventsRepository
      .getAvailableUsersLookups(propertyId)
      .pipe(map((options) => mapLookupItemsToDropdownOptions(options)));
  }

  create(propertyId: number, event: ICreatePropertyEventPayload): Observable<void> {
    return this.#propertiesEventsRepository.create(propertyId, event);
  }

  getEventAppointments(query: IEventAppointmentQuery): Observable<ISignalPaginatedResponse<ISmallListTableInput>> {
    return this.#propertiesEventsRepository
      .getEventAppointments(query)
      .pipe(mapEventAppointmentsToSmallTableInputOperator());
  }

  toggleAttendance(propertyId: number, appointmentId: number): Observable<void> {
    return this.#propertiesEventsRepository.toggleAttendance(propertyId, appointmentId);
  }

  cancelAppointment(propertyId: number, appointmentId: number): Observable<void> {
    return this.#propertiesEventsRepository.cancelAppointment(propertyId, appointmentId);
  }

  getTimeslotAppointment(
    propertyId: number,
    query: IEventTimeslotAppointmentQuery,
  ): Observable<IEventTimeslotAppointment[]> {
    return this.#propertiesEventsRepository.getTimeslotAppointment(propertyId, query);
  }

  getAttendedCustomers(query: IEventAppointmentQuery): Observable<ISignalPaginatedResponse<ISmallListTableInput>> {
    return this.#propertiesEventsRepository
      .getAttendedCustomers(query)
      .pipe(mapAttendedCustomersToSmallTableInputOperator());
  }

  toggleFavouriteCustomer(customerId: number): Observable<void> {
    return this.#propertiesEventsRepository.toggleFavouriteCustomer(customerId);
  }

  getEventDetails(propertyId: number, eventId: number): Observable<IPropertyEventDetails> {
    return this.#propertiesEventsRepository.getEventDetails(propertyId, eventId);
  }

  getPropertyEventById(propertyId: number, eventId: number) {
    return this.#propertiesEventsRepository.getPropertyEventById(propertyId, eventId);
  }

  editPropertyEventById(propertyId: number, eventId: number, body: IEditPropertyEventPayload) {
    return this.#propertiesEventsRepository.editPropertyEventById(propertyId, eventId, body);
  }

  getUnitsAssignedToEvents(eventId: number) {
    return this.#propertiesEventsRepository.getUnitsAssignedToEvents(eventId);
  }

  getEventInstances(
    propertyId: number,
    eventId: number,
    queryParams: IPropertyEventInstancesPayload,
  ): Observable<ISignalPaginatedResponse<IPropertyEventInstance[]>> {
    return this.#propertiesEventsRepository.getEventInstances(propertyId, eventId, queryParams).pipe(
      map((paginatedEventInstances) => {
        return {
          ...mapToSignalPaginatedResponse(paginatedEventInstances),
        } as ISignalPaginatedResponse<IPropertyEventInstance[]>;
      }),
    );
  }

  getEventCustomers(
    propertyId: number,
    eventId: number,
    queryParams: IPropertyEventCustomersPayload,
  ): Observable<ISignalPaginatedResponse<IPropertyEventCustomers[]>> {
    return this.#propertiesEventsRepository.getEventCustomers(propertyId, eventId, queryParams).pipe(
      map((paginatedEventInstances) => {
        return {
          ...mapToSignalPaginatedResponse(paginatedEventInstances),
        } as ISignalPaginatedResponse<IPropertyEventCustomers[]>;
      }),
    );
  }

  getAvailableTimeSlot(propertyId: number, eventInstanceId: number): Observable<IAvailableTimeSlot[]> {
    return this.#propertiesEventsRepository.getAvailableTimeSlot(propertyId, eventInstanceId);
  }

  changeTimeSlot(propertyId: number, eventInstanceId: number, body: Partial<IChangeTimeSlotInputs>): Observable<void> {
    return this.#propertiesEventsRepository.changeTimeSlot(propertyId, eventInstanceId, body);
  }

  matchCustomer(query: IMatchCustomerQuery): Observable<IMatchCustomerResponse> {
    return this.#propertiesEventsRepository.matchCustomer(query);
  }

  inviteCustomerToEvent(model: IInviteCustomerModel, query: IInviteCustomerQuery) {
    const dto = this.#inviteCustomerAdapter.toDto(model);
    return this.#propertiesEventsRepository.inviteCustomerToEvent(dto, query);
  }

  stopRepetitionOnEventById(propertyId: number, eventId: number) {
    return this.#propertiesEventsRepository.stopRepetitionOnEventById(propertyId, eventId);
  }
}
