export const SUPPORTED_DATE_FORMAT_FOR_DISPLAY = "dd/MM/yyyy";

export const SUPPORTED_DATE_FORMATS_FOR_PARSING = [
  "dd.MM.yyyy",
  "dd.MM.yy",
  "d.MM.yyyy",
  "d.MM.yy",
  "dd.M.yyyy",
  "dd.M.yy",
  "d.M.yyyy",
  "d.M.yy",
  "dd-MM-yyyy",
  "dd-MM-yy",
  "d-MM-yyyy",
  "d-MM-yy",
  "dd-M-yyyy",
  "dd-M-yy",
  "d-M-yyyy",
  "d-M-yy",
  "dd/MM/yyyy",
  "dd/MM/yy",
  "d/MM/yyyy",
  "d/MM/yy",
  "dd/M/yyyy",
  "dd/M/yy",
  "d/M/yyyy",
  "d/M/yy",
  "yyyy-MM-dd",
  "yy-MM-dd",
  "yyyy-MM-d",
  "yy-MM-d",
  "yyyy-M-dd",
  "yy-M-dd",
  "yyyy-M-d",
  "yy-M-d",
  "yyyy/MM/dd",
  "yy/MM/dd",
  "yyyy/MM/d",
  "yy/MM/d",
  "yyyy/MM/dd",
  "yy/MM/dd",
  "yyyy/M/dd",
  "yy/M/dd",
  "yyyy/M/d",
  "yy/M/d",
  "MMM d, yyyy",
  "d/M",
  "dd/MM",
  "d-M",
  "dd-MM",
];
