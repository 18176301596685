import { Component, DestroyRef, effect, EventEmitter, inject, input, OnInit, Output } from "@angular/core";
import { ButtonComponent } from "@root/shared/button/button.component";
import { IDemographicQuestionLookUp } from "@root/data/market/demographic-questions/models/demographic-question-look-up.model";
import { MatFormField, MatOption, MatSelect } from "@angular/material/select";
import { MatDivider } from "@angular/material/divider";
import { FormArray, FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ButtonDropdownComponent } from "@root/shared/button-dropdown/button-dropdown.component";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { DemographicQuestionAnswerComponent } from "@root/shared/customer-card/components/customer-card-details/components/customer-information/components/demographic-question-answer/demographic-question-answer.component";
import { castControlFromAbstractToFormControl } from "@root/shared/utilities/form.utilities";
import { DemographicQuestionsService } from "@root/data/market/demographic-questions/services/demographic-questions.service";
import { mapDemographicQuestionLookUpToDropdownOption } from "@root/data/market/demographic-questions/utilities/demographic-questions.utilities";
import { constructDemographicQuestionAnswerFormGroup } from "@root/shared/utilities/customer.utilities";

@Component({
  selector: "est-select-demographic-question-button",
  standalone: true,
  imports: [
    ButtonComponent,
    MatSelect,
    MatOption,
    MatDivider,
    MatFormField,
    ReactiveFormsModule,
    ButtonDropdownComponent,
    DemographicQuestionAnswerComponent,
  ],
  templateUrl: "./select-demographic-question-button.component.html",
  styleUrl: "./select-demographic-question-button.component.scss",
})
export class SelectDemographicQuestionButtonComponent implements OnInit {
  @Output() valuesChanged = new EventEmitter<void>();
  readonly #demographicQuestionService = inject(DemographicQuestionsService);
  readonly #destroyRef = inject(DestroyRef);
  demographicQuestionAnswerFormArray = input.required<FormArray<FormGroup>>();
  demographicQuestionLookUps = input.required<IDemographicQuestionLookUp[]>();
  control = input<FormControl>(new FormControl());
  legalEntityId = input<number>();
  dropdownOptions: IDropdownOption[] = [];
  readonly setOptionsEffect$ = effect(() => {
    this.dropdownOptions = mapDemographicQuestionLookUpToDropdownOption(this.demographicQuestionLookUps());
    this.control().setValue(this.demographicQuestionAnswerFormArray().value.map((x) => x.questionId));
  });
  protected readonly castControlFromAbstractToFormControl = castControlFromAbstractToFormControl;

  ngOnInit() {
    this.control()
      .valueChanges.pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((questionIds: number[]) => this.handleAddOrRemoveDemographicQuestionAnswer(questionIds));
  }

  protected handleAddOrRemoveDemographicQuestionAnswer(questionIds: number[]) {
    questionIds.forEach((id) => {
      if (
        !this.demographicQuestionAnswerFormArray()
          .value.map((x) => x.questionId)
          .includes(id)
      ) {
        this.#addNewDemographicQuestionAnswer(id);
      }
    });

    this.demographicQuestionAnswerFormArray().value.forEach((value) => {
      if (questionIds.includes(value.questionId)) return;
      if (this.legalEntityId())
        this.#demographicQuestionService
          .removeDemographicQuestionAnswer(value.id)
          .subscribe(() => this.#filterDemographicQuestionAnswerFormArray(value.id));
      else this.#filterDemographicQuestionAnswerFormArray(value.id);
    });
  }

  protected answerDemographicQuestion(questionAnswer: any) {
    if (!this.legalEntityId()) return;
    const sendAnswer = (answerOptionIds?: number[], textAnswer?: string) => {
      this.#demographicQuestionService
        .answerDemographicQuestion(questionAnswer.questionId, this.legalEntityId()!, answerOptionIds, textAnswer)
        .subscribe();
    };

    if (questionAnswer.answerOptionIds instanceof Array) {
      sendAnswer(questionAnswer.answerOptionIds);
    } else if (questionAnswer.textAnswer) {
      sendAnswer(undefined, questionAnswer.textAnswer);
    } else {
      sendAnswer([questionAnswer.answerOptionIds]);
    }
  }

  #addNewDemographicQuestionAnswer(questionId: number) {
    const demographicQuestionLookUp = this.demographicQuestionLookUps().find((x) => x.id == questionId)!;
    if (!this.legalEntityId()) {
      this.demographicQuestionAnswerFormArray().push(
        constructDemographicQuestionAnswerFormGroup(demographicQuestionLookUp),
      );
      return;
    }
    const isSelectField = demographicQuestionLookUp.demographicQuestionAnswerOptions.length > 0;
    this.#demographicQuestionService
      .answerDemographicQuestion(
        questionId,
        this.legalEntityId()!,
        isSelectField ? [demographicQuestionLookUp.demographicQuestionAnswerOptions[0].id] : [],
        isSelectField ? undefined : "",
      )
      .subscribe(() => this.valuesChanged.emit());
  }

  #filterDemographicQuestionAnswerFormArray(id: number) {
    const index = this.demographicQuestionAnswerFormArray().controls.findIndex((control) => control.value.id === id);
    this.demographicQuestionAnswerFormArray().removeAt(index);
  }
}
