<div
  #phone
  [ngClass]="
    isElementCloseToBottom(phone, NUMBER_OF_PX_TO_SHOW_DROPDOWN_TO_TOP)
      ? 'move-dropdown-to-top'
      : 'move-dropdown-to-down'
  "
  class="relative"
>
  @if (control().disabled) {
    <div class="absolute inset-0 z-10 bg-white opacity-50"></div>
  }
  <ngx-intl-tel-input
    [attr.aria-labelledby]="'PHONE_VALIDATION.ARIA_LABEL' | translate"
    [enableAutoCountrySelect]="false"
    [enablePlaceholder]="true"
    [formControl]="control()"
    [maxLength]="15"
    [numberFormat]="PhoneNumberFormat.National"
    [phoneValidation]="true"
    [preferredCountries]="[CountryISO.Denmark, CountryISO.Sweden]"
    [searchCountryField]="[SearchCountryField.DialCode, SearchCountryField.Iso2, SearchCountryField.Name]"
    [searchCountryFlag]="true"
    [searchCountryPlaceholder]="'PHONE_VALIDATION.PLACEHOLDER' | translate"
    [selectFirstCountry]="true"
    [selectedCountryISO]="getCountyISO"
    [separateDialCode]="separateDialCode()"
    class="!mt-20"
    id="phone-input"
  ></ngx-intl-tel-input>
  @if (this.control().touched && this.control().dirty) {
    @if (this.control().hasError("required")) {
      <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center">
        <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
        {{ "PHONE_VALIDATION.REQUIRED" | translate }}
      </mat-error>
    } @else if (this.control().invalid) {
      <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center">
        <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
        {{ "PHONE_VALIDATION.INVALID" | translate }}
      </mat-error>
    }
  }
</div>
