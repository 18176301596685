import { environment } from "../../../../environments/environment";

export const DemographicQuestionsApis = {
  removeDemographicQuestionAnswer: environment.apiUrl + "DemographicQuestions/RemoveDemographicQuestionAnswer",
  answerDemographicQuestion: (id: number) =>
    environment.apiUrl + `DemographicQuestions/AnswerDemographicQuestion/${id}`,
  organisation: {
    getAllDemographicQuestions: environment.apiUrl + "DemographicQuestions/Organisation/List",
    getById: (id: number) => environment.apiUrl + `DemographicQuestions/Organisation/Get/${id}`,
    create: environment.apiUrl + "DemographicQuestions/Organisation/Create",
    update: environment.apiUrl + "DemographicQuestions/Organisation/Update",
    toggleActivation: (id: number) => environment.apiUrl + `DemographicQuestions/Organisation/ToggleActivation/${id}`,
    deleteDemographicQuestion: (id: number) => environment.apiUrl + `DemographicQuestions/Organisation/Delete/${id}`,
    lookUp: environment.apiUrl + "Organizations/LookUpOrganisationDemographicQuestions",
  },
};
