import { IUser } from "../models/user.model";
import { map, OperatorFunction } from "rxjs";
import { signal } from "@angular/core";
import { RoleNameMapper } from "../mappers/role-name.mapper";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { UserRole } from "@root/data/market/users/enums/user-role.enum";
import { IUserRole } from "@root/data/market/users/models/user-role.model";
import { USER_ROLES_ORDERED_REVERSED } from "@root/shared/constants/global.constants";

export const mapPaginatedUsersToDropdownOptionsOperator = (
  currentDivisionId: number,
): OperatorFunction<IPaginatedResponse<IUser>, ISignalPaginatedResponse<IDropdownOption>> => {
  return map((paginatedUsers) => ({
    ...mapToSignalPaginatedResponse(paginatedUsers),
    results: signal(
      paginatedUsers.results.map((user) => ({
        label: `${user.firstName} ${user.lastName} ${getUserRoleInCurrentDivision(user, currentDivisionId)}`,
        value: user.id,
      })),
    ),
  }));
};

export const mapToSignalResponseAllUsers = (): OperatorFunction<any, any> => {
  return map((paginatedUsers: IPaginatedResponse) => {
    return {
      ...mapToSignalPaginatedResponse(paginatedUsers),
      results: signal(mapUsersToSmallListTableInput(paginatedUsers.results)),
    };
  });
};

const getUserRoleInCurrentDivision = (user: IUser, currentDivisionId: number): string => {
  if (user.organisationRole === UserRole.SystemAdmin) {
    return RoleNameMapper.get(UserRole.SystemAdmin)!;
  }
  const userRole = user.userDivisionRoles.find(
    (userDivisionRole) => userDivisionRole.divisionId === currentDivisionId,
  )!.roleName;
  return RoleNameMapper.get(userRole)!;
};

const mapUsersToSmallListTableInput = (users: IUser[]): ISmallListTableInput[] => {
  return users.map((user: any) => {
    // remove joined and lastActive after backend return their values
    return {
      ...user,
      role: `${RoleNameMapper.get(user.role)!}`,
      joined: new Date().toISOString(),
      lastActive: new Date().toISOString(),
    };
  });
};

export const sortUserRoles = (userRoles: IUserRole[]): IUserRole[] => {
  return userRoles.sort((a, b) => {
    const orderA = USER_ROLES_ORDERED_REVERSED.indexOf(a.name);
    const orderB = USER_ROLES_ORDERED_REVERSED.indexOf(b.name);

    return orderA - orderB;
  });
};
