import { Injectable } from "@angular/core";
import { IInviteCustomerOnsiteDto } from "@root/data/market/legalEntities/models/invite-customer-onsite-dto.model";
import { IInviteCustomerOnsiteModel } from "@root/data/market/legalEntities/models/invite-customer-onsite.model";
import { ModelAdapter } from "@root/data/market/shared/adapters/model.adapater";
import { removeNullsFromObject, setEmptyStringsToNull } from "@root/shared/utilities/object.utilities";
import { mapCommunicationMethods } from "@root/data/market/legalEntities/utilities/legal-entities.utilities";

@Injectable({
  providedIn: "root",
})
export class InviteCustomerOnsiteAdapter extends ModelAdapter<IInviteCustomerOnsiteDto, IInviteCustomerOnsiteModel> {
  override fromDto(): IInviteCustomerOnsiteModel {
    throw new Error("Method not implemented.");
  }

  override toDto(model: IInviteCustomerOnsiteModel): IInviteCustomerOnsiteDto {
    const dto = {
      eventInstanceId: model.eventInstanceId!,
      eventTimeSlotId: model.eventTimeSlotId,
      responsibleAgentId: (model.customer as any)["responsibleAgentId"],
      customer: {
        ...model.customer,
        id: model.id,
        type: model.customer.customerType,
        address: model.customer.addressInformation,
        nationalityCountryId: model.customer.nationalityId,
      },
      followUp: {
        ...model.followUp,
        journeyFollowUpTasks: model.followUp.followUpTasks.map((task) => ({
          followUpTaskId: task.followUpTaskId!,
          note: task.followUpNote!,
          notificationDate: task.date!,
        })),
      },
      notificationMethod: {
        body: model.notificationMethod.message,
        subject: model.notificationMethod.subject,
        communicationMethods: mapCommunicationMethods({
          myPage: model.notificationMethod.communicationMethodMyPage,
          sms: model.notificationMethod.communicationMethodSMS,
        }),
        notifiableAgentsIDs: model.notificationMethod.responsibleUserId
          ? [model.notificationMethod.responsibleUserId]
          : [],
      },
    };
    setEmptyStringsToNull(dto);
    removeNullsFromObject(dto);
    return dto;
  }
}
