<form (focusout)="updatePinControls()" [formGroup]="pinForm" class="relative">
  @if (pinControl().disabled || countryIdControl().disabled) {
    <div class="absolute inset-0 z-10 bg-white opacity-50"></div>
  }
  <span
    [ngClass]="{ 'opacity-50': pinControl().disabled || countryIdControl().disabled }"
    class="px-[0.25rem] bg-white absolute text-black-500 text-xs z-10 font-medium"
    id="label-span"
  >
    {{ "PIN.LABEL" | translate }}
  </span>
  <div
    [ngClass]="{ 'border-error-200': pinForm.touched && pinForm.invalid }"
    class="flex items-center justify-between overflow-x-hidden border-[1px] min-w-[18.75rem] max-w-fit rounded-lg border-black-300"
  >
    <est-language-dropdown
      [appliedClasses]="'w-[4.375rem] h-[2.75rem] border-r-[1px] border-black-300 bg-black-100 rounded-l'"
      [control]="countryFormControl"
      [countries]="countries()"
      [id]="'pin-input'"
      [selectWithLanguageId]="true"
      [withErrorMessage]="false"
      [withLabel]="false"
      [withLanguageName]="false"
      selectMultipleOptions="false"
    />

    <input
      #pinPart1Element
      (keyup)="onPinPart1Change($event)"
      [formControl]="pinPart1"
      class="w-[60px] mx-0.5"
      maxlength="6"
      minlength="6"
      placeholder="201189"
      type="text"
    />
    <span>-</span>
    <input
      #pinPart2Element
      (focus)="onPinPart2Change($event)"
      (keyup)="onPinPart2Change($event)"
      [formControl]="pinPart2"
      [type]="showPin ? 'text' : 'password'"
      class="w-12 mx-0.5"
      maxlength="4"
      minlength="4"
      placeholder="****"
    />
    <mat-icon (click)="togglePinVisibility()" class="mr-4 hover:cursor-pointer"
      >{{ showPin ? "visibility_off" : "visibility" }}
    </mat-icon>
  </div>

  @if (pinForm.touched && pinForm.invalid) {
    <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
      @if (showCountryIsRequiredError()) {
        <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
        {{ "PIN.COUNTRY" | translate }} {{ "VALIDATION.IS_REQUIRED" | translate }}
      }
      @if (showPinErrorMessage()) {
        <mat-icon class="ml-2 !text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
        {{ "PIN.SHORT_LABEL" | translate }} {{ getPinErrorMessage() | translate }}
      }
    </mat-error>
  }
</form>
