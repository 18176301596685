<div [ngClass]="appliedClass()" class="w-[18.75rem] h-32">
  <mat-form-field [attr.aria-required]="isRequired()" appearance="outline" hideRequiredMarker="true" id="textarea">
    @if (label()) {
      <mat-label
        >{{ label() | translate }}
        @if (isRequired()) {
          {{ "REQUIRED" | translate }}
        }
      </mat-label>
    }
    <textarea
      [formControl]="control()"
      [placeholder]="placeholder() | translate"
      class="!resize-none"
      matInput
    ></textarea>
  </mat-form-field>
  @if (shouldShowControlRequiredError(control())) {
    <mat-error class="!text-error-200 text-[0.5rem] font-normal !flex items-center pt-1">
      <mat-icon class="!text-error-200 text-sm font-normal !h-5 !w-4">error</mat-icon>
      {{ label() | translate }} {{ "VALIDATION.IS_REQUIRED" | translate }}
    </mat-error>
  }
</div>
