import {
  booleanAttribute,
  Component,
  DestroyRef,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  input,
  OnInit,
  Output,
  signal,
  ViewChild,
  WritableSignal,
} from "@angular/core";
import { MatDivider } from "@angular/material/divider";
import { MatError, MatFormField, MatLabel } from "@angular/material/form-field";
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect, MatSelectTrigger } from "@angular/material/select";
import { AsyncPipe, NgClass, NgOptimizedImage } from "@angular/common";
import { SearchInputComponent } from "../search-input/search-input.component";
import { TranslateModule } from "@ngx-translate/core";
import { IDropdownOption } from "../interfaces/dropdown-option.interface";
import { FormControl, ReactiveFormsModule, Validators } from "@angular/forms";
import { isElementScrolledToTheBottom } from "../utilities/element-poistion.utilities";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { MatIcon } from "@angular/material/icon";
import { SpinnerComponent } from "../spinner/spinner.component";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "est-select-search-field",
  standalone: true,
  imports: [
    MatDivider,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgOptimizedImage,
    SearchInputComponent,
    TranslateModule,
    ReactiveFormsModule,
    NgClass,
    NgOptimizedImage,
    MatError,
    MatIcon,
    SpinnerComponent,
    MatSelectTrigger,
    AsyncPipe,
  ],
  templateUrl: "./select-search-field.component.html",
  styleUrl: "./select-search-field.component.scss",
})
export class SelectSearchFieldComponent implements OnInit {
  @Input() disabledOptions: (string | number)[] = [];
  @ViewChild("containerUi", { static: true }) containerUi!: ElementRef<HTMLDivElement>;
  @ViewChild("matSelect", { static: true }) matSelect!: MatSelect;
  @ViewChild("searchInputComponent", { static: true }) searchInputComponent!: SearchInputComponent;
  @Input({ required: true }) searchSignal!: WritableSignal<any>;
  @Input() selectSignal?: WritableSignal<any>;
  @Input() selectedDropdownOption: WritableSignal<IDropdownOption | undefined> = signal(undefined);
  @Input() selectedMultipleDropdownOptions: WritableSignal<IDropdownOption[] | undefined> = signal(undefined);
  initialValue = input<any>();
  dropdownOptions = input.required<IDropdownOption[]>();
  isMultiple = input(false);
  searchDebounceTime = input(500);
  currentPage = input(1);
  isNoMoreResults = input(false);
  totalPages = input<number | undefined>();
  isLoading = input(false);
  containsAllOption = input(false, {
    transform: booleanAttribute,
  });
  dropdownLabel = input<string>("");
  appliedClass = input<string>("");
  control = input<FormControl>(new FormControl());
  cachedDropdownOptions: IDropdownOption[] = []; // Used if isMultiple is true to prevent material clearing control value if no options are found
  @Output() scrollEnd = new EventEmitter<number>();
  sanitizer = inject(DomSanitizer);
  protected readonly Validators = Validators;
  protected readonly isElementScrolledToTheBottom = isElementScrolledToTheBottom;
  readonly #populateCachedDropdownOptions = effect(() => {
    if (!this.searchSignal())
      // Only cache it if we are
      this.cachedDropdownOptions = structuredClone(this.dropdownOptions());
  });
  private readonly destroyRef$ = inject(DestroyRef);

  get currentOptionLabel(): string | undefined {
    if (!this.isMultiple()) {
      return (this.matSelect.selected as MatOption)?._text?.nativeElement.innerHTML;
    } else {
      return (this.matSelect.selected as MatOption[]).map((option) => option._text?.nativeElement.innerText).join(", ");
    }
  }

  ngOnInit() {
    this.control().value && !this.isMultiple() && this.#setSelectedDropdownOption(this.control().value);
    this.control().value && this.isMultiple() && this.#setSelectedMultipleDropdownOptions(this.control().value);
    this.updateSignalAndClearSearchOnControlChange();
  }

  resetSearchValue() {
    this.searchSignal.set("");
    if (this.searchInputComponent.search) this.searchInputComponent.search.nativeElement.value = ""; // This is null on the initial call when containsAllOption is true
  }

  emitScrollEnd() {
    if (this.totalPages() && this.currentPage() >= this.totalPages()!) {
      return;
    }
    const nextPageIndex = this.currentPage() + 1;
    this.scrollEnd.emit(nextPageIndex);
  }

  addRequiredToLabel(): string {
    return this.control().hasValidator(Validators.required) ? "REQUIRED" : "";
  }

  private updateSignalAndClearSearchOnControlChange(): void {
    this.control()
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef$))
      .subscribe((value) => {
        if (!this.isMultiple()) {
          this.#setSelectedDropdownOption(value);
        } else {
          this.#setSelectedMultipleDropdownOptions(value);
        }
        this.resetSearchValue();
        this.selectSignal?.set(value);
      });
  }

  #setSelectedDropdownOption(value: any): void {
    if (this.dropdownOptions().length === 0) return;
    this.selectedDropdownOption.set(this.dropdownOptions().find((option) => option.value === value));
  }

  #setSelectedMultipleDropdownOptions(values: any[]): void {
    if (this.dropdownOptions().length === 0) return;
    const options: IDropdownOption[] = [];
    values.forEach((value) => {
      const option = this.dropdownOptions().find((option) => option.value === value);
      option && options.push(option);
    });
    this.selectedMultipleDropdownOptions.set(options);
  }
}
