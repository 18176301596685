<mat-form-field
  [class]="appliedClass()"
  appearance="outline"
  class="w-[18.75rem]"
  id="search"
  subscriptSizing="dynamic"
>
  <input
    [disabled]="isDisabled()"
    #searchInput
    [attr.aria-labelledby]="placeholder() | translate"
    [placeholder]="(placeholder() | translate) + '...'"
    matInput
    type="search"
    [formControl]="control()"
  />
  <mat-icon matSuffix>search</mat-icon>
</mat-form-field>
