<button
  (click)="toggleFavourite()"
  [disabled]="control().disabled"
  [ngClass]="[appliedClasses(), control().disabled ? 'opacity-50' : '']"
  class="h-full"
  type="button"
>
  <img
    [alt]="(control().value ? 'UNFAVORITE' : 'FAVORITE') | translate"
    [ngSrc]="control().value ? 'assets/images/icons/heart-solid.svg' : 'assets/images/icons/heart-outlined.svg'"
    class="max-w-none my-auto"
    height="17"
    priority="true"
    width="20"
  />
</button>
