import { IUnitResponseOverview } from "@root/data/market/units/models/unit-response-overview.model";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { signal } from "@angular/core";
import { ILargeListTableInput } from "@root/shared/interfaces/large-list-table-input.interface";
import { UnitStatusTranslationMapper } from "@root/data/market/units/mappers/unit-status-translation.mapper";
import { UnitSubStatusTranslationMapper } from "@root/data/market/units/mappers/unit-substatus-translation.mapper";
import { TranslateService } from "@ngx-translate/core";
import { OperatorFunction } from "rxjs/internal/types";
import { map } from "rxjs/internal/operators/map";
import { UnitSubStatus } from "@root/data/market/units/enums/unit-substatus.enum";

export const mapUnitsToSignalResidentialUnitsInputOperator = (
  translation: TranslateService,
): OperatorFunction<
  IPaginatedResponse<IUnitResponseOverview>,
  ISignalPaginatedResponse<ILargeListTableInput<string>>
> => {
  return map((paginatedUnits) => ({
    ...mapToSignalPaginatedResponse(paginatedUnits),
    results: signal(mapUnitsToResidentialTable(paginatedUnits.results, translation)),
  }));
};
const mapUnitsToResidentialTable = (
  units: IUnitResponseOverview[],
  translateService: TranslateService,
): ILargeListTableInput<string>[] => {
  return units.map((unit) => ({
    ...unit,
    id: unit.unitId,
    header: {
      isAdvertised: unit.isAdvertised,
      unitStatuses: UnitStatusTranslationMapper.get(unit.mainStatus),
      subheader: unit.unitNo,
    },
    isFavourite: unit.isFavourite,
    floor: getTranslatedFloor(unit.floor, translateService),
    subStatus: UnitSubStatusTranslationMapper.get(unit.subStatus),
    address: unit.address,
    price: unit.rentPrice,
    paymentFrequency: "-",
  }));
};

const getFloorSuffix = (floor: number): string => {
  const suffixes: { [key: string]: string } = { 1: "st", 2: "nd", 3: "rd" };
  return suffixes[floor] || "th";
};

const getTranslatedFloor = (floor: number, translateService: TranslateService): string => {
  if (floor === null || floor === undefined) {
    return "-";
  }

  const specialFloors: { [key: string]: string } = {
    "-1": "BASEMENT",
    "0": "GROUND_FLOOR",
  };

  const translationKey = specialFloors[floor] || "ADDRESS.FLOOR";
  const translation = translateService.instant(translationKey);

  if (floor in specialFloors) {
    return translation;
  }

  const suffix = getFloorSuffix(floor % 10);
  return `${floor}${suffix} ${translation}`;
};

export const getSubStatusClasses = (subStatus: string) => {
  return {
    "bg-status-completed-light": subStatus === "UNIT_STATUS.COMPLETED",
    "bg-status-inProcess-light": subStatus === "UNIT_STATUS.IN_PROCESS",
    "bg-status-available-light": subStatus === "UNIT_STATUS.AVAILABLE",
    "bg-status-notActive-light": subStatus === "UNIT_STATUS.NOT_ACTIVE",
  };
};

export const getMainStatusClasses = (unitStatuses: string) => {
  return {
    "bg-status-completed": unitStatuses === "UNIT_STATUS.COMPLETED",
    "bg-status-inProcess": unitStatuses === "UNIT_STATUS.IN_PROCESS",
    "bg-status-available": unitStatuses === "UNIT_STATUS.AVAILABLE",
    "bg-status-notActive": unitStatuses === "UNIT_STATUS.NOT_ACTIVE",
  };
};

export const getUnitSubStatusClasses = (unitStatuses: UnitSubStatus) => {
  return {
    "bg-status-completed-200": [UnitSubStatus.Vacant, UnitSubStatus.Occupied, UnitSubStatus.ReadyForSale].includes(
      unitStatuses,
    ),
    "bg-status-inProcess-dark": [
      UnitSubStatus.OngoingScreening,
      UnitSubStatus.ActiveContract,
      UnitSubStatus.CompletedScreening,
      UnitSubStatus.ApprovedScreening,
      UnitSubStatus.AgreementInProcess,
      UnitSubStatus.SigningOrderInProcess,
      UnitSubStatus.SigningOrderPaused,
      UnitSubStatus.PendingConditionsCleared,
    ].includes(unitStatuses),
    "bg-status-notActive": [UnitSubStatus.Archived, UnitSubStatus.NotListed].includes(unitStatuses),
    "bg-status-completed": [UnitSubStatus.Sold, UnitSubStatus.Leased, UnitSubStatus.PendingMoveIn],
  };
};
