import { signal } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IDropdownOption } from "@root/shared/interfaces/dropdown-option.interface";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { map } from "rxjs/internal/operators/map";
import { OperatorFunction } from "rxjs/internal/types";
import { sortDefaultLanguage } from "../../marketing-questions/utilities/marketing-questions.utilities";
import { IDemographicQuestionLookUp } from "../models/demographic-question-look-up.model";
import { IDemographicQuestion } from "../models/demographic-question.model";

export const mapDemographicQuestionsToSignalResponseSmallTableInput = (
  translateService: TranslateService,
): OperatorFunction<
  IPaginatedResponse<IDemographicQuestion>,
  ISignalPaginatedResponse<ISmallListTableInput<number>>
> => {
  return map((paginatedResponse: IPaginatedResponse) => {
    return {
      ...mapToSignalPaginatedResponse(paginatedResponse),
      results: signal(mapDemographicQuestionsToSmallListTableInput(paginatedResponse.results, translateService)),
    };
  });
};

export const mapDemographicQuestionsToSmallListTableInput = (
  demographicQuestions: IDemographicQuestion[],
  translateService: TranslateService,
): ISmallListTableInput[] => {
  return demographicQuestions.map((demographicQuestion) => {
    return {
      ...demographicQuestion,
      source: demographicQuestion.source ?? "-",
      createdBy: demographicQuestion.createdBy ?? "-",
      createdAt: demographicQuestion.createdAt ?? "-",
      defaultQuestion: demographicQuestion.defaultQuestion
        ? translateService.instant("SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.SYSTEM_DEFAULT")
        : translateService.instant("SETTINGS.MARKETING.SURVEYS.DEMOGRAPHIC_QUESTIONS.MANUALLY_ADDED"),
      answerOptionCount: getTranslatedAnswerOptionsCount(demographicQuestion.answerOptionCount!, translateService),
      mouseoverMenuInputs: {
        lastChangedDate: demographicQuestion.updatedAt,
        externalTitles: sortDefaultLanguage(demographicQuestion.externalTitles),
        internalTitle: demographicQuestion.internalTitle,
      },
    };
  });
};

const getTranslatedAnswerOptionsCount = (count: number, translateService: TranslateService): string => {
  const optionsTranslated = translateService.instant("OPTIONS");
  const noOptionsTranslated = translateService.instant("NO_OPTIONS");
  return count > 0 ? `${count} ${optionsTranslated}` : noOptionsTranslated;
};

export const mapDemographicQuestionLookUpToDropdownOption = (
  demographicQuestions: IDemographicQuestionLookUp[],
): IDropdownOption[] => {
  return demographicQuestions.map((dq) => {
    return {
      label: dq.value,
      value: dq.id,
    };
  });
};
