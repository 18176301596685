import { inject, Injectable } from "@angular/core";
import { ModelAdapter } from "@root/data/market/shared/adapters/model.adapater";
import { IInviteCustomerDto } from "@root/data/market/properties/models/invite-customer-dto.model";
import { IInviteCustomerModel } from "@root/data/market/properties/models/invite-customer.model";
import { mapCommunicationMethods } from "@root/data/market/legalEntities/utilities/legal-entities.utilities";
import { removeNullsFromObject, setEmptyStringsToNull } from "@root/shared/utilities/object.utilities";
import { AuthService } from "../../auth/services/auth.service";

@Injectable({ providedIn: "root" })
export class InviteCustomerAdapter extends ModelAdapter<IInviteCustomerDto, IInviteCustomerModel> {
  #authService = inject(AuthService);

  fromDto(): IInviteCustomerModel {
    throw new Error("Method not implemented.");
  }

  toDto(model: IInviteCustomerModel): IInviteCustomerDto {
    console.log(model);
    const dto = {
      ...model,
      journeyIds: model.journeyIds,
      responsibleAgentId: (model.customer as any)["responsibleAgentId"],
      legalEntityId: model.id,
      documentIds: model.documentsIds,
      notificationMethod: {
        ...model.notificationMethod,
        body: model.notificationMethod!.message,
        communicationMethods: mapCommunicationMethods({
          myPage: model.notificationMethod!.communicationMethodMyPage,
          sms: model.notificationMethod!.communicationMethodSMS,
        }),
        notifiableAgentsIDs: model.notificationMethod!.responsibleUserId
          ? [model.notificationMethod!.responsibleUserId]
          : [],
      },
      ...(!model.id && { legalEntityRequest: model.customer }),
    } as IInviteCustomerDto;
    setEmptyStringsToNull(dto);
    removeNullsFromObject(dto);
    return dto;
  }
}
