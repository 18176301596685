import { inject } from "@angular/core";
import { FormArray, FormControl, FormGroup, NonNullableFormBuilder, Validators } from "@angular/forms";
import { IAddressFormGroup } from "@root/shared/interfaces/address-form-group.interface";
import { IDemographicQuestionLookUp } from "@root/data/market/demographic-questions/models/demographic-question-look-up.model";
import { CustomerFollowUpTaskFormGroup } from "@root/views/main/property/property-events/customer-follow-up/customer-follow-up-form-group.type";
import { ISendInvitationFormGroup } from "@root/shared/send-invitiation-to-customer/send-invitiation-formgroup.type";
import { CustomerType } from "@root/data/market/legalEntities/enums/customer-type.enum";

// Must be run in injection context
export const constructCustomerInformationFormGroup = () => {
  const fb = inject(NonNullableFormBuilder);
  return fb.group(
    {
      preferredLanguageId: fb.control("", { updateOn: "change" }),
      useOnly2FSigning: fb.control(false, { updateOn: "change" }),
      exemptedFromDigitalCommunication: fb.control(false, { updateOn: "change" }),
      noSmsPhone: fb.control(false, { updateOn: "change" }),
      pinCountryId: fb.control(""),
      pin: fb.control(""),
      firstName: fb.control("", Validators.required),
      lastName: fb.control("", Validators.required),
      phoneNumber: fb.control(""),
      phoneIsdCode: fb.control(""),
      customerCategoryId: fb.control<number | null>(null, { updateOn: "change" }),
      customerType: fb.control(CustomerType.Private, { updateOn: "change" }),
      nationalityId: fb.control(""),
      birthday: fb.control<string>(""),
      email: fb.control("", [Validators.email, Validators.required]),
      addressInformation: fb.group<IAddressFormGroup>({
        companyName: fb.control(""),
        careOf: fb.control(""),
        vat: fb.control(""),
        countryId: fb.control("", Validators.required),
        streetName: fb.control(""),
        houseNumber: fb.control(""),
        floor: fb.control("", { updateOn: "change" }),
        side: fb.control("", { updateOn: "change" }),
        door: fb.control("", { updateOn: "change" }),
        zipCode: fb.control(""),
        city: fb.control(""),
      }),
      demographicQuestionAnswers: fb.array<FormGroup>([]),
      isFavourite: fb.control(false),
    },
    { updateOn: "blur" },
  );
};

export const constructCustomerFollowUpFormGroup = () => {
  return new FormGroup({
    interestRating: new FormControl<number | null>(null),
    legalEntityRating: new FormControl<number | null>(null),
    followUpStatusId: new FormControl<number | null>(null),
    followUpTasks: new FormArray<CustomerFollowUpTaskFormGroup>([]),
    responsibleAgentId: new FormControl<number | null>(null),
    responsibleAgentName: new FormControl<string>("", {
      nonNullable: true,
    }),
    selectedMarketingQuestionsIds: new FormControl<number[]>([], {
      nonNullable: true,
    }),
  });
};

export const constructDemographicQuestionAnswerFormGroup = (demographicQuestionLookUp: IDemographicQuestionLookUp) => {
  return new FormGroup({
    questionId: new FormControl<number>(demographicQuestionLookUp.id),
    textAnswer: new FormControl<string>(""),
    answerOptionIds: new FormControl<number | number[]>([]),
  });
};

export const constructSendInvitationFormGroup = (isRequired = false): FormGroup<ISendInvitationFormGroup> => {
  return new FormGroup({
    eventDate: new FormControl<string | null>(null),
    startTime: new FormControl<string | null>(null),
    endTime: new FormControl<string | null>(null),
    externalTitle: new FormControl<string | null>(null),
    recipientIds: new FormControl<number[]>([], {
      nonNullable: true,
    }),
    responsibleUserId: new FormControl<number | null>(null, {
      validators: isRequired ? [Validators.required] : [],
    }),
    responsibleAgentName: new FormControl<string | null>(null),
    emailTemplateId: new FormControl<number | null>(null),
    subject: new FormControl<string | null>(null, {
      validators: isRequired ? [Validators.required] : [],
    }),
    message: new FormControl<string>("", {
      nonNullable: true,
      validators: isRequired ? [Validators.required] : [],
    }),
    communicationMethodEmail: new FormControl<boolean>(
      {
        value: true,
        disabled: true,
      },
      {
        nonNullable: true,
      },
    ),
    communicationMethodSMS: new FormControl<boolean>(false, {
      nonNullable: true,
    }),
    communicationMethodMyPage: new FormControl<boolean>(false, {
      nonNullable: true,
    }),
  });
};
