import { signal } from "@angular/core";
import { UserPropertyEventAvailabilityTranslationMapper } from "@root/data/market/properties/mappers/user-property-event-availability-translation.mapper";
import { IEventAttendedCustomer } from "@root/data/market/properties/models/event-attended-customer.model";
import { IEventCustomerAppointment } from "@root/data/market/properties/models/event-customer-appointment.model";
import { IPropertyEventAssignedUser } from "@root/data/market/properties/models/property-event-assigned-user.model";
import { IPaginatedResponse } from "@root/shared/interfaces/paginated-response.interface";
import { ISignalPaginatedResponse } from "@root/shared/interfaces/signal-paginated-response.interface";
import { ISmallListTableInput } from "@root/shared/interfaces/small-list-table-input.interface";
import { mapToSignalPaginatedResponse } from "@root/shared/utilities/signals.utilities";
import { map, OperatorFunction } from "rxjs";

export const mapPropertyEventUserToSmallTableInputOperator = (): OperatorFunction<
  IPaginatedResponse<IPropertyEventAssignedUser>,
  ISignalPaginatedResponse<ISmallListTableInput>
> => {
  return map((paginatedUsers) => ({
    ...mapToSignalPaginatedResponse(paginatedUsers),
    results: signal(
      paginatedUsers.results.map((user) => ({
        ...user,
        name: `${user.firstName} ${user.lastName}`,
        availability: UserPropertyEventAvailabilityTranslationMapper.get(user.availability)!,
        teams: user.teams.join(", "),
        mouseoverMenuInputs: {
          firstName: user.firstName,
          lastName: user.lastName,
          phoneNumber: user.phoneNumber,
          email: user.email,
          title: user.title,
          workPlace: user.workPlace,
          joinedAt: user.joinedAt,
          lastActive: user.lastActive,
          systemLanguage: user.systemLanguage,
          lastChanged: user.lastChanged,
          imageUrl: user.imageUrl,
        },
      })),
    ),
  }));
};

export const mapEventAppointmentsToSmallTableInputOperator = (): OperatorFunction<
  IPaginatedResponse<IEventCustomerAppointment>,
  ISignalPaginatedResponse<ISmallListTableInput>
> => {
  return map((res) => ({
    ...mapToSignalPaginatedResponse(res),
    results: signal(
      res.results.map((customer) => ({
        ...customer,
        phoneNumber: customer.phoneNumber || undefined,
      })),
    ),
  }));
};

export const mapAttendedCustomersToSmallTableInputOperator = (): OperatorFunction<
  IPaginatedResponse<IEventAttendedCustomer>,
  ISignalPaginatedResponse<ISmallListTableInput>
> => {
  return map((res) => ({
    ...mapToSignalPaginatedResponse(res),
    results: signal(
      res.results.map((customer) => ({
        ...customer,
        id: customer.customerId,
        mouseoverMenuInputs: {
          firstName: customer.customerDetails.firstName,
          lastName: customer.customerDetails.lastName,
          phoneNumber: customer.customerDetails.phoneNumber,
          email: customer.customerDetails.email,
          age: customer.customerDetails.age,
          birthday: customer.customerDetails.birthday,
          country: customer.customerDetails.country,
          nationality: customer.customerDetails.nationality,
          preferredLanguage: customer.customerDetails.preferredLanguage?.name,
          lastChanged: customer.customerDetails.updatedAt,
          privacyConfirmation: customer.customerDetails.latestPrivacyConfirmationDate,
          marketingConfirmation: customer.customerDetails.latestMarketingConfirmationDate,
          creationDate: customer.customerDetails.createdAt,
        },
      })),
    ),
  }));
};
