import { Component, EventEmitter, input, Output } from "@angular/core";
import { NgClass, NgOptimizedImage } from "@angular/common";
import { TranslateModule } from "@ngx-translate/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: "est-heart-button",
  standalone: true,
  imports: [NgOptimizedImage, TranslateModule, NgClass],
  templateUrl: "./heart-button.component.html",
  styleUrl: "./heart-button.component.scss",
})
export class HeartButtonComponent {
  appliedClasses = input<string>("");
  control = input<FormControl>(new FormControl());
  @Output() favourite: EventEmitter<boolean> = new EventEmitter();

  toggleFavourite(): void {
    this.control().patchValue(!this.control().value);
    this.favourite.emit(this.control().value);
  }
}
