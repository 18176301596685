import { computed, inject, Injectable, signal, WritableSignal } from "@angular/core";
import { ITimezone } from "@root/data/market/timezones/models/timezone.model";
import { SystemLanguage } from "@root/shared/enums/language.enum";
import { LanguageService } from "@root/shared/ui-services/language.service";
import { Observable, Subject, tap } from "rxjs";
import { UserRole } from "../../users/enums/user-role.enum";
import { IUserDivisionRole } from "../../users/models/user-division-role.model";
import { IUser } from "../../users/models/user.model";
import { AuthRepository } from "../respositories/auth.repository";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentUserDivisionRole: IUserDivisionRole | undefined = undefined;
  divisionChange$ = new Subject<void>();
  readonly #authRepository = inject(AuthRepository);
  #languageService = inject(LanguageService);
  #_user: WritableSignal<IUser | null> = signal(null);
  readonlyUser = computed(() => this.#_user());

  getCurrentUserRole(): UserRole | undefined {
    return this.#_user()?.organisationRole === UserRole.SystemAdmin
      ? UserRole.SystemAdmin
      : this.currentUserDivisionRole?.roleName;
  }

  registerUser(): Observable<IUser> {
    return this.#authRepository.getLoggedInUser().pipe(
      tap((user) => {
        this.#_user.set(user);
        this.#languageService.setUserLanguage(this.#_user()?.systemLanguage || SystemLanguage.English);
        if (user.lastDivisionAccessedId) {
          this.#selectUserDivisionBasedOnId(user.lastDivisionAccessedId);
        } else if (user.userDivisionRoles.length > 0) {
          this.currentUserDivisionRole = user.userDivisionRoles[0];
        }
      }),
    );
  }

  refreshUser(): void {
    this.registerUser().subscribe();
  }

  saveLastUserDivision(divisionId: number): Observable<void> {
    this.divisionChange$.next();
    return this.#authRepository.switchUserDivision(divisionId);
  }

  logout(): void {
    this.#_user.set(null);
  }

  getUserDivisionRoles(): IUserDivisionRole[] | undefined {
    return this.#_user()?.userDivisionRoles;
  }

  getFullName(): string | undefined {
    return this.#_user()?.firstName + " " + this.#_user()?.lastName;
  }

  getUserId() {
    return this.#_user()?.id;
  }

  getOrganisationName() {
    return this.#_user()?.organisationName;
  }

  getOrganisationTimeZone(): ITimezone | undefined {
    return this.#_user()?.organisationTimeZone;
  }

  updateUserTimeZone(timezone: ITimezone) {
    if (this.#_user()?.organisationTimeZone) {
      this.#_user.update((u) => {
        u!.organisationTimeZone = timezone;
        return u;
      });
    }
  }

  #selectUserDivisionBasedOnId(divisionId: number): void {
    this.currentUserDivisionRole = this.#_user()?.userDivisionRoles.find((role) => role.divisionId === divisionId);
  }

  getOrganizationLogo() {
    return this.#_user()?.organisationLogo;
  }
}
