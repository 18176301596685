<form (focusout)="valuesChanged.emit()" [formGroup]="formGroup()" class="flex flex-col gap-4">
  <div class="flex flex-col gap-3.5">
    @if (hasSearchBar()) {
      <est-small-header
        appliedClasses="!mb-0"
        heading="CUSTOMER_CARD.CUSTOMER_DETAILS.CUSTOMER_INFORMATION"
        tooltip="CUSTOMER_CARD.CUSTOMER_DETAILS.CUSTOMER_INFORMATION"
        subheading="CUSTOMER_CARD.CUSTOMER_DETAILS.SEARCH_DESCRIPTION"
      ></est-small-header>
      <est-search-customers-bar (selectedCustomer)="selectedCustomerHandle($event)"></est-search-customers-bar>
    }
    <est-small-header
      appliedClasses="!mb-0"
      heading="CUSTOMER_CARD.CUSTOMER_DETAILS.CUSTOMER_INFORMATION"
      tooltip="CUSTOMER_CARD.CUSTOMER_DETAILS.CUSTOMER_INFORMATION"
    ></est-small-header>

    <div class="flex flex-wrap gap-4">
      <est-select-field
        (changeSelectValue)="valuesChanged.emit()"
        [control]="castControlFromAbstractToFormControl(formGroup().controls['customerType'])"
        [dropDownOptions]="customerTypeOptions"
        [selectMultipleOptions]="false"
        dropDownLabel="CUSTOMER_CARD.CUSTOMER_DETAILS.CUSTOMER_TYPE"
      ></est-select-field>
      @if (getCustomerType === CustomerType.Private) {
        <est-select-field
          [selectMultipleOptions]="false"
          (changeSelectValue)="valuesChanged.emit()"
          dropDownLabel="CUSTOMER_CARD.CUSTOMER_DETAILS.CUSTOMER_CATEGORY"
          [dropDownOptions]="customerCategories()"
          [control]="castControlFromAbstractToFormControl(formGroup().controls['customerCategoryId'])"
        ></est-select-field>
      }
    </div>
    <div class="flex flex-wrap gap-4">
      <est-select-field
        (changeSelectValue)="valuesChanged.emit()"
        [control]="
          castControlFromAbstractToFormControl(
            castControlFromAbstractToFormGroup(formGroup().controls['addressInformation']).controls['countryId']
          )
        "
        [dropDownOptions]="addressLookUps().countries"
        [selectMultipleOptions]="false"
        appliedClass="w-[18.75rem]"
        dropDownLabel="COUNTRY"
      ></est-select-field>
      @if (getCustomerType === CustomerType.Private) {
        <est-pin-input
          (valuesChanged)="valuesChanged.emit()"
          [pinControl]="castControlFromAbstractToFormControl(formGroup().controls['pin'])"
          [countryIdControl]="castControlFromAbstractToFormControl(formGroup().controls['pinCountryId'])"
          [countries]="countries()"
        ></est-pin-input>
      } @else {
        <est-input
          appliedClass="w-[18.75rem]"
          [control]="
            castControlFromAbstractToFormControl(
              castControlFromAbstractToFormGroup(formGroup().controls['addressInformation']).controls['vat']
            )
          "
          [label]="'BILLING.VAT_NUMBER'"
        ></est-input>
      }
    </div>
    @if (getCustomerType === CustomerType.Company) {
      <div class="flex flex-wrap gap-4">
        <est-input
          appliedClass="w-[18.75rem]"
          [control]="
            castControlFromAbstractToFormControl(
              castControlFromAbstractToFormGroup(formGroup().controls['addressInformation']).controls['companyName']
            )
          "
          label="COMPANIES.COMPANY"
        ></est-input>
        <est-input
          appliedClass="w-[18.75rem]"
          [control]="
            castControlFromAbstractToFormControl(
              castControlFromAbstractToFormGroup(formGroup().controls['addressInformation']).controls['careOf']
            )
          "
          label="C/O"
        ></est-input>
        <est-heart-button [control]="isFavourite()"></est-heart-button>
      </div>
    }
    <div class="flex flex-wrap gap-4">
      <est-input
        [control]="castControlFromAbstractToFormControl(formGroup().controls['firstName'])"
        appliedClass="w-[18.75rem]"
        label="FIRST_NAME"
      ></est-input>
      <est-input
        [control]="castControlFromAbstractToFormControl(formGroup().controls['lastName'])"
        appliedClass="w-[18.75rem]"
        label="LAST_NAME"
      ></est-input>
      @if (getCustomerType === CustomerType.Private) {
        <est-heart-button [control]="isFavourite()"></est-heart-button>
      }
    </div>
    <div class="flex flex-wrap gap-4">
      <est-input
        [control]="castControlFromAbstractToFormControl(formGroup().controls['email'])"
        appliedClass="w-[18.75rem]"
        label="EMAIL"
        type="email"
      ></est-input>
      <est-phone-input [control]="phoneControl()"></est-phone-input>
    </div>
    @if (!collapsed && !collapsible()) {
      <div class="flex flex-wrap gap-4">
        <est-input
          [control]="
            castControlFromAbstractToFormControl(
              castControlFromAbstractToFormGroup(formGroup().controls['addressInformation']).controls['streetName']
            )
          "
          appliedClass="w-[18.75rem]"
          label="ADDRESS.STREET"
        ></est-input>
        <est-input
          appliedClass="w-[9.375rem]"
          label="NUMBER"
          [control]="
            castControlFromAbstractToFormControl(
              castControlFromAbstractToFormGroup(formGroup().controls['addressInformation']).controls['houseNumber']
            )
          "
        ></est-input>
        <est-select-field
          [dropDownOptions]="addressLookUps().floors"
          [selectMultipleOptions]="false"
          appliedClass="w-[9.375rem]"
          dropDownLabel="ADDRESS.FLOOR"
          (changeSelectValue)="valuesChanged.emit()"
          [control]="
            castControlFromAbstractToFormControl(
              castControlFromAbstractToFormGroup(formGroup().controls['addressInformation']).controls['floor']
            )
          "
        ></est-select-field>
        <est-select-field
          [dropDownOptions]="ADDRESS_SIDE_OPTIONS"
          [selectMultipleOptions]="false"
          appliedClass="w-[9.375rem]"
          dropDownLabel="ADDRESS.SIDE"
          (changeSelectValue)="valuesChanged.emit()"
          [control]="
            castControlFromAbstractToFormControl(
              castControlFromAbstractToFormGroup(formGroup().controls['addressInformation']).controls['side']
            )
          "
        ></est-select-field>
        <est-select-field
          [dropDownOptions]="ADDRESS_DOOR_OPTIONS"
          [selectMultipleOptions]="false"
          (changeSelectValue)="valuesChanged.emit()"
          appliedClass="w-[9.375rem]"
          dropDownLabel="ADDRESS.DOOR"
          [control]="
            castControlFromAbstractToFormControl(
              castControlFromAbstractToFormGroup(formGroup().controls['addressInformation']).controls['door']
            )
          "
        ></est-select-field>
      </div>
      <div class="flex flex-wrap gap-4">
        <est-input
          appliedClass="w-[9.375rem]"
          label="ADDRESS.ZIP_CODE"
          type="number"
          [control]="
            castControlFromAbstractToFormControl(
              castControlFromAbstractToFormGroup(formGroup().controls['addressInformation']).controls['zipCode']
            )
          "
        ></est-input>
        <est-input
          appliedClass="w-[18.75rem]"
          label="ADDRESS.CITY"
          [control]="
            castControlFromAbstractToFormControl(
              castControlFromAbstractToFormGroup(formGroup().controls['addressInformation']).controls['city']
            )
          "
        ></est-input>
      </div>
    }
  </div>
  @if (!collapsed) {
    @if (formGroup().controls["customerType"].value === CustomerType.Private) {
      <div class="flex flex-col gap-4">
        <est-small-header
          appliedClasses="!mb-2"
          heading="CUSTOMER_CARD.CUSTOMER_DETAILS.NATIONALITY.NAME"
          subheading="CUSTOMER_CARD.CUSTOMER_DETAILS.NATIONALITY.DESCRIPTION"
          tooltip="CUSTOMER_CARD.CUSTOMER_DETAILS.NATIONALITY.NAME"
        ></est-small-header>
        <est-select-field
          (changeSelectValue)="valuesChanged.emit()"
          dropDownLabel="CUSTOMER_CARD.CUSTOMER_DETAILS.NATIONALITY.NAME"
          [dropDownOptions]="addressLookUps().countries"
          [selectMultipleOptions]="false"
          [control]="castControlFromAbstractToFormControl(formGroup().controls['nationalityId'])"
        ></est-select-field>
      </div>
      <div class="flex flex-col gap-4">
        <est-small-header
          appliedClasses="!mb-2"
          heading="CUSTOMER_CARD.CUSTOMER_DETAILS.BIRTHDAY.NAME"
          subheading="CUSTOMER_CARD.CUSTOMER_DETAILS.BIRTHDAY.DESCRIPTION"
          tooltip="CUSTOMER_CARD.CUSTOMER_DETAILS.BIRTHDAY.NAME"
        ></est-small-header>
        <est-datepicker
          [dateOnly]="true"
          (valueChange)="formatBirthday()"
          appliedClasses="w-[18.75rem]"
          label="CUSTOMER_CARD.CUSTOMER_DETAILS.BIRTHDAY.NAME"
          [control]="castControlFromAbstractToFormControl(formGroup().controls['birthday'])"
        ></est-datepicker>
      </div>
    }
  }
</form>
@if (!collapsed) {
  <hr class="border-black-100 my-6 absolute start-0 end-0" />
  <div class="flex mt-12 flex-col gap-4">
    <est-small-header
      appliedClasses="!mb-2"
      heading="CUSTOMER_CARD.CUSTOMER_DETAILS.PREFERRED_LANGUAGE.NAME"
      subheading="CUSTOMER_CARD.CUSTOMER_DETAILS.PREFERRED_LANGUAGE.DESCRIPTION"
      tooltip="CUSTOMER_CARD.CUSTOMER_DETAILS.PREFERRED_LANGUAGE.NAME"
    ></est-small-header>
    <est-language-dropdown
      [countries]="languages()"
      [control]="castControlFromAbstractToFormControl(formGroup().controls['preferredLanguageId'])"
      (selectionChanged)="valuesChanged.emit()"
      [selectMultipleOptions]="false"
      [selectWithLanguageId]="true"
    ></est-language-dropdown>
  </div>
  <hr class="border-black-100 my-6 absolute start-0 end-0" />
  @if (showSettings()) {
    <div class="flex mt-12 flex-col gap-4">
      <est-small-header
        appliedClasses="!mb-2"
        heading="CUSTOMER_CARD.CUSTOMER_DETAILS.SETTINGS.NAME"
        subheading="CUSTOMER_CARD.CUSTOMER_DETAILS.SETTINGS.DESCRIPTION"
        tooltip="CUSTOMER_CARD.CUSTOMER_DETAILS.SETTINGS.NAME"
      ></est-small-header>
      <mat-slide-toggle
        [checked]="castControlFromAbstractToFormControl(formGroup().controls['useOnly2FSigning']).value"
        [formControl]="castControlFromAbstractToFormControl(formGroup().controls['useOnly2FSigning'])"
        (change)="valuesChanged.emit()"
        >{{ "CUSTOMER_CARD.CUSTOMER_DETAILS.SETTINGS.ONLY_2F_SIGNING" | translate }}
      </mat-slide-toggle>
      <mat-slide-toggle
        [checked]="castControlFromAbstractToFormControl(formGroup().controls['exemptedFromDigitalCommunication']).value"
        [formControl]="castControlFromAbstractToFormControl(formGroup().controls['exemptedFromDigitalCommunication'])"
        (change)="valuesChanged.emit()"
        >{{ "CUSTOMER_CARD.CUSTOMER_DETAILS.SETTINGS.EXEMPTED_DIGITAL_COMMUNICATION" | translate }}
      </mat-slide-toggle>
      <mat-slide-toggle
        [checked]="castControlFromAbstractToFormControl(formGroup().controls['noSmsPhone']).value"
        [formControl]="castControlFromAbstractToFormControl(formGroup().controls['noSmsPhone'])"
        (change)="valuesChanged.emit()"
        >{{ "CUSTOMER_CARD.CUSTOMER_DETAILS.SETTINGS.NO_SMS_PHONE" | translate }}
      </mat-slide-toggle>
    </div>
  }
  @if (formGroup().controls["customerType"].value === CustomerType.Private) {
    <hr class="border-black-100 my-6 absolute start-0 end-0" />
    <div class="flex mt-12 flex-col gap-4">
      <est-small-header
        appliedClasses="!mb-2"
        heading="CUSTOMER_CARD.CUSTOMER_DETAILS.DEMOGRAPHICS.NAME"
        subheading="CUSTOMER_CARD.CUSTOMER_DETAILS.DEMOGRAPHICS.DESCRIPTION"
        tooltip="CUSTOMER_CARD.CUSTOMER_DETAILS.DEMOGRAPHICS.NAME"
      ></est-small-header>
      <est-select-demographic-question-button
        (valuesChanged)="reloadRequest.emit()"
        [legalEntityId]="legalEntityId()"
        [demographicQuestionLookUps]="demographicQuestions()"
        [demographicQuestionAnswerFormArray]="
          castControlFromAbstractToFormArray(formGroup().controls['demographicQuestionAnswers'])
        "
      ></est-select-demographic-question-button>
    </div>
  }
}
@if (collapsible()) {
  <div class="w-full flex justify-center items-center text-sm font-light">
    <button class="flex gap-2 text-primary mt-6 items-center" (click)="toggleCollapsed()">
      {{ getCollapsedButtonText | translate }}
      <img
        [alt]="'SELECT_MENU' | translate"
        height="8"
        ngSrc="assets/images/icons/arrow-down.svg"
        priority="true"
        width="14"
        [ngClass]="{ 'rotate-180': !collapsed }"
      />
    </button>
  </div>
}
