import {
  AfterViewInit,
  Component,
  effect,
  ElementRef,
  Input,
  input,
  OnDestroy,
  signal,
  ViewChild,
  WritableSignal,
} from "@angular/core";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { MatFormField, MatSuffix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { TranslateModule } from "@ngx-translate/core";
import { fromEvent } from "rxjs/internal/observable/fromEvent";
import { debounceTime } from "rxjs/internal/operators/debounceTime";
import { distinctUntilChanged } from "rxjs/internal/operators/distinctUntilChanged";
import { map } from "rxjs/internal/operators/map";
import { Subscription } from "rxjs/internal/Subscription";

@Component({
  selector: "est-search-input",
  standalone: true,
  imports: [MatIcon, MatSuffix, MatFormField, MatInput, TranslateModule, ReactiveFormsModule],
  templateUrl: "./search-input.component.html",
  styleUrl: "./search-input.component.scss",
})
export class SearchInputComponent implements AfterViewInit, OnDestroy {
  control = input<FormControl<any>>(new FormControl());
  isDisabled = input<boolean>(false);
  placeholder = input<string>("SEARCH");
  appliedClass = input<string>();
  debounceTime = input(500);
  @Input() searchSignal: WritableSignal<string> = signal("");

  @ViewChild("searchInput") search!: ElementRef<HTMLInputElement>;

  searchInputValue$: Subscription = new Subscription();

  #disableEffect = effect(() => {
    if (this.isDisabled()) {
      this.control().disable();
    } else {
      this.control().enable();
    }
  });

  ngAfterViewInit(): void {
    this.searchInputValue$ = fromEvent<Event>(this.search.nativeElement, "input")
      .pipe(
        map((event) => (event.target as HTMLInputElement).value.trim()),
        debounceTime(this.debounceTime()),
        distinctUntilChanged(),
      )
      .subscribe((value: string) => {
        this.searchSignal.set(value);
      });
    if (this.control().value) {
      this.searchSignal.set(this.control().value);
    }
  }

  ngOnDestroy(): void {
    this.searchInputValue$.unsubscribe();
  }
}
